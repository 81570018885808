@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy/Gilroy-Regular.woff2') format('woff2'),
        url('Gilroy/Gilroy-Regular.woff')  format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy/Gilroy-Medium.woff2') format('woff2'),
        url('Gilroy/Gilroy-Medium.woff')  format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy/Gilroy-Bold.woff2') format('woff2'),
        url('Gilroy/Gilroy-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy/Gilroy-ExtraBold.woff2') format('woff2'),
        url('Gilroy/Gilroy-ExtraBold.woff')  format('woff');
    font-weight: 800;
    font-style: normal;
}